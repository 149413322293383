import { CrudResource } from "@/Core/UI/CrudResource/CrudResource";
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';

export const PurchaseExportCallCrud = () => {

    const crudContext = useCrudResourceContext();

    return <CrudResource 
        resource='Purchase.PurchaseExportCall'
        list={{
            restUriReplaceParams: {
                entity_id: crudContext.formContext.values.id
            }
        }}
        isFilter={false}
    />
}