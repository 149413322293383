import { ClientFormTemplate } from "../Templates/ClientFormTemplate";
import { useShowErrorNotificationTask } from "@/Ship/Tasks/ShowErrorNotificationTask";
import { toJS } from "mobx";
import { UserStore } from "@/Core/Stores/UserStore";

export const ClientResource = {
    title: 'Клієнти',
    fields: {
        title: {
            label: 'Назва',
            isRequired: true
        },
        fullName: {
            label: 'Повна назва'
        },
        edrpou: {
            label: 'Код ЄДРПОУ'
        },
        ipn: {
            label: 'ІПН'
        },
        vat: {
            label: 'VAT'
        },
        email: {
            label: 'Пошта'
        },
        phones: {
            label: 'Телефони',
            isMultiple: true
        },
        otherContact: {
            label: 'Інші контактні дані',
            type: 'textarea'
        },
        director: {
            label: 'Директор'
        },
        address: {
            label: 'Адреса'
        },
        onBasis: {
            label: 'Директор діє на підставі'
        },
        iban: {
            label: 'Рахунок IBAN'
        },
        bank: {
            label: 'Банк рахунку'
        },
        isNonResident: {
            label: 'Нерезидент',
            type: 'switch'
        },
        country: {
            label: 'Країна',
            type: 'select',
            resource: 'Setting.ListCountry'
        },
        comment: {
            label: 'Коментар',
        }
    },
    drawer: {
        width: 800
    },
    form: {
        template: ClientFormTemplate,
        eventHandlers: {
            onBeforeSendSaveRequest: {
                validate: ({ values }) => {
                    
                    const ShowErrorNotificationTask = useShowErrorNotificationTask();

                    if( 
                        !values.isNonResident 
                        && !values.edrpou 
                        && values.phones.filter( phone => phone.length > 0 ).length === 0 
                    ) {
                        ShowErrorNotificationTask('Помилка валідації', 'Заповніть "Код ЄДРПОУ" або "Телефони".');
                        return false;
                    }

                    if( 
                        values.isNonResident === true 
                        && !values.vat 
                        && values.phones.filter( phone => phone.length > 0 ).length === 0  
                    ) {
                        ShowErrorNotificationTask('Помилка валідації', 'Заповніть "ПДВ" або "Телефони".');
                        return false;
                    }
                }
            }
        }
    },
    restUri: '/core_api/sale/clients',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: !['ROLE_BUYER'].some(value => UserStore.user?.roles.includes(value)) ? true : false,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_OFFICE_MANAGER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};