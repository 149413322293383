import { CrudResource } from "@/Core/UI/CrudResource/CrudResource";
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';

export const SaleExportCallCrud = () => {

    const crudContext = useCrudResourceContext();

    return <CrudResource 
        resource='Sale.SaleExportCall'
        list={{
            restUriReplaceParams: {
                entity_id: crudContext.formContext.values.id
            }
        }}
        isFilter={false}
    />
}