import { DatePickerInput } from '../DatePickerInput/DatePickerInput';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button, Dropdown, Input } from 'antd';
import { CiCalendarDate } from "react-icons/ci";
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import { ConfigStore } from '@/Core/Stores/ConfigStore';
import localeUk from 'air-datepicker/locale/uk';
import localeEn from 'air-datepicker/locale/en';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const DatePickerRangeInput = ({ field, index, options = {} }) => {

    const { t } = useTranslation();
    const fieldHiddenRef = useRef(null);
    const datepickerRef = useRef(null);
    const AppConfig = ConfigStore.get('AppConfig');

    const optionsRange = {
        range: true,
        timepicker: false,
        ...options,
        multipleDatesSeparator: ' - '
    };

    const { onChange, ...props } = field.bind({ index });

    const IconObserver = observer(() => {
        return <>
            {field.value && !props?.disabled && <span className="wl-datepicker-input-clear ant-select-clear" unselectable="on" aria-hidden="true" style={{ userSelect: 'none' }} onClick={() => {
                onChange(null);
            }}>
                <span role="img" aria-label="close-circle" className="anticon anticon-close-circle">
                    <svg fillRule="evenodd" viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                        <path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64zm127.98 274.82h-.04l-.08.06L512 466.75 384.14 338.88c-.04-.05-.06-.06-.08-.06a.12.12 0 00-.07 0c-.03 0-.05.01-.09.05l-45.02 45.02a.2.2 0 00-.05.09.12.12 0 000 .07v.02a.27.27 0 00.06.06L466.75 512 338.88 639.86c-.05.04-.06.06-.06.08a.12.12 0 000 .07c0 .03.01.05.05.09l45.02 45.02a.2.2 0 00.09.05.12.12 0 00.07 0c.02 0 .04-.01.08-.05L512 557.25l127.86 127.87c.04.04.06.05.08.05a.12.12 0 00.07 0c.03 0 .05-.01.09-.05l45.02-45.02a.2.2 0 00.05-.09.12.12 0 000-.07v-.02a.27.27 0 00-.05-.06L557.25 512l127.87-127.86c.04-.04.05-.06.05-.08a.12.12 0 000-.07c0-.03-.01-.05-.05-.09l-45.02-45.02a.2.2 0 00-.09-.05.12.12 0 00-.07 0z"></path>
                    </svg>
                </span>
            </span>}
            {field.value && !props?.disabled && <span className="wl-datepicker-suffix">
                <CiCalendarDate />
            </span>}
            {!field.value && !props?.disabled && <span className="wl-datepicker-suffix no-hide">
                <CiCalendarDate />
            </span>}
        </>
    });

    let datepickerOptions = {
        isMobile: true,
        timepicker: false,
        locale: AppConfig?.currentLocale === 'uk' ? localeUk : localeEn,
        dateFormat: 'yyyy-MM-dd',
    };

    let toPushButtons = [];

    if( Array.isArray( field.props?.buttons ) )
        field.props.buttons.map( ({ onClick, ...button }) => {
            if( typeof onClick === 'function' )
                button.onClick = () => onClick({ onChange });
            toPushButtons.push(button);
        });
    
    let items = [
        ...toPushButtons,
        { 
            label: t("Select date", { ns: 'Core' }), 
            key: 'select-date',
            onClick: () => {

                if( datepickerRef.current )
                    datepickerRef.current.destroy();
                
                datepickerRef.current = new AirDatepicker(fieldHiddenRef.current, {
                    ...datepickerOptions,
                    range: false,
                    onSelect: ({ date }) => {
                        if( date !== undefined ) {
                            onChange([
                                dayjs(date).startOf('day'),
                                dayjs(date).endOf('day')
                            ]);
                            datepickerRef.current.hide();
                        }
                    }
                });
                   
                datepickerRef.current.show();
            }
        },
        { 
            label: t("Select period", { ns: 'Core' }), 
            key: 'select-range',
            onClick: () => {

                if( datepickerRef.current )
                    datepickerRef.current.destroy();

                datepickerRef.current = new AirDatepicker(fieldHiddenRef.current, {
                    ...datepickerOptions,
                    range: true,
                    onSelect: ({ date }) => {                       
                        if( date.length === 2 ) {
                            onChange([
                                dayjs(date[0]).startOf('day'),
                                dayjs(date[1]).endOf('day')
                            ]);
                            datepickerRef.current.hide();
                        }
                    }
                });
                   
                datepickerRef.current.show();
            }
        },
        { 
            label: t("Yesterday", { ns: 'Core' }), 
            key: 'yesterday',
            onClick: () => {
                onChange([ 
                    dayjs().startOf('day').add(-1, 'day').startOf('day'), 
                    dayjs().endOf('day').add(-1, 'day').endOf('day')
                ]);
            }
        },
        { 
            label: t("Today", { ns: 'Core' }), 
            key: 'today',
            onClick: () => {
                onChange([ 
                    dayjs().startOf('day').startOf('day'), 
                    dayjs().endOf('day').endOf('day')
                ]);
            }
        },
        { 
            label: t("Tomorrow", { ns: 'Core' }), 
            key: 'tomorrow',
            onClick: () => {
                onChange([ 
                    dayjs().startOf('day').add(1, 'day').startOf('day'), 
                    dayjs().endOf('day').add(1, 'day').endOf('day')
                ]);
            }
        },
        { 
            label: t("Current week", { ns: 'Core' }), 
            key: 'current-week',
            onClick: () => {
                const startOfWeek = dayjs().startOf('week').add(1, 'day').startOf('day');
                const endOfWeek = dayjs().endOf('week').add(1, 'day').endOf('day');
                onChange([startOfWeek, endOfWeek]);
            }
        },
        { 
            label: t("Current month", { ns: 'Core' }), 
            key: 'current-month',
            onClick: () => {
                const startOfMonth = dayjs().startOf('month').startOf('day');
                const endOfMonth = dayjs().endOf('month').endOf('day');
                onChange([startOfMonth, endOfMonth]);
            }
        }
    ];

    const InputField = observer(() => {
        
        const value = field.value;
        
        const valueFormated = Array.isArray(value) ? `${value[0].format("YYYY-MM-DD")} - ${value[1].format("YYYY-MM-DD")}`: '';
    
        return <input 
            className='wl-datepicker-input'
            readOnly={true}
            {...props}
            value={valueFormated}
        />
    });

    useEffect(() => {
        
        datepickerRef.current = new AirDatepicker(fieldHiddenRef.current, datepickerOptions);

        return () => {
            if( datepickerRef.current && typeof datepickerRef.current.destroy === 'function' ) {
                datepickerRef.current.destroy();
                datepickerRef.current = null;
            }
        };
    }, []);

    return <>
        <input 
            ref={fieldHiddenRef} 
            readOnly={true}
            type='hidden'
        />
        <div className="wl-datepicker">
            <Dropdown menu={{ items }} trigger={['click']}>
                <div><InputField /></div>
            </Dropdown>
            <IconObserver />
        </div>
    </>
};