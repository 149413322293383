import { App } from "antd";
import { useDefaultLayoutContext } from "@/Core/Layouts/DefaultLayout/DefaultLayoutContextProvider";

export const useShowInfoNotificationTask = () => {

    const { notification } = App.useApp();

    const resources = {
        application: "Purchase.Application",
        sale: "Sale.Sale",
        purchase: "Purchase.Purchase"
    };

    const defaultLayoutContext = useDefaultLayoutContext();

    const createClickHandler = (type, id) => (e) => {
        e.preventDefault();
        defaultLayoutContext.openResource({ 
            resourceCode: resources[type], 
            resourceId: id
        });
    };

    const replaceBBCode = (str) => {
        if( typeof str === 'string' )
            return str.replace(/\[(\w+)=(\d+)\]\[#\d+\]([^\[]+)\[\/\1\]/g, (match, type, id, content) => {
                if (resources[type]) {
                    return `<a href="#" data-type="${type}" data-id="${id}">${content.trim()}</a>`;
                }
                return match;
            });

        return str;
    };

    const handleClick = (e) => {
        if (e.target.tagName === 'A' && e.target.hasAttribute('data-type') && e.target.hasAttribute('data-id')) {
            const type = e.target.getAttribute('data-type');
            const id = e.target.getAttribute('data-id');
            createClickHandler(type, id)(e);
        }
    };

    const ShowInfoNotificationTask = (message, description) => {
    
        notification.info({
            duration: 3,
            message: <div 
                dangerouslySetInnerHTML={{ __html: replaceBBCode(notification.message) }} 
                onClick={handleClick}
            />,
            description,
            placement: 'bottomRight'
        });
    
        return false;
    }

    return ShowInfoNotificationTask;
}