export const SaleExportCallResource = {
    title: 'Лог запитів API',
    fields: {
        datetime: {
            label: 'Дата та час',
            type: 'datetime',
            inputProps: {
                disabled: true
            }
        },
        type: {
            label: 'Тип запиту',
            type: 'select',
            options: [
                { value: 'create', label: 'Створення' },
                { value: 'update', label: 'Оновлення' },
                { value: 'delete', label: 'Видалення' },
            ],
            inputProps: {
                disabled: true
            }
        },
        codeHTTP: {
            label: 'HTTP код',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        status: {
            label: 'Статус',
            type: 'select',
            options: [
                { value: 'Success', label: 'Успіх' },
                { value: 'Error', label: 'Помилка' },
            ],
            inputProps: {
                disabled: true
            }
        },
        info: {
            label: 'Додаткова інформація',
            type: 'textarea',
            inputProps: {
                disabled: true
            }
        },
        entity: {

        }
    },
    restUri: {
        get: '/core_api/sale/sales/export_calls',
        getCollection: '/core_api/sale/sales/{entity_id}/export_calls'
    },
    accessFn: mode => mode === 'read' ? true : false
}