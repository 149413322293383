import i18n from '@/Ship/i18n';

export const DefaultValueConfig = {
    backendUserRolesList: [
        { label: 'Супер адміністратор', value: 'ROLE_ADMIN' },
        { label: 'Адміністратор', value: 'ROLE_SUPER_USER' },
        { label: 'Закупівельник', value: 'ROLE_BUYER' },
        { label: 'Продажі. Керівник відділу', value: 'ROLE_SENIOR_MANAGER' },
        { label: 'Продажі', value: 'ROLE_MANAGER' },
        { label: 'Логіст', value: 'ROLE_LOGIST' },
        { label: 'Бухгалтер', value: 'ROLE_ACCOUNTANT' },
        { label: 'Брокер', value: 'ROLE_BROKER' },
        { label: 'Контролер', value: 'ROLE_CONTROLLER' },
        { label: 'Executor', value: 'ROLE_EXECUTOR' },
        { label: 'Офіс-менеджер', value: 'ROLE_OFFICE_MANAGER' }
    ],
    currencyDefaultValue: {
        '@id': '/core_api/settings/currencies/1',
        title: 'Гривня'
    }
};